import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import * as Popover from '@radix-ui/react-popover';

import {
  inputLabelClasses,
  Select,
  InputMask,
  inputBaseClasses,
  inputSizeClasses,
  ReactSelectWithFlags,
  Textarea,
} from 'src/ui/components';

import { useGetPhoneType } from 'src/hooks/useGetPhoneType';

import { usePersonAlertStore } from '../../store/personAlertStore';

import * as utilityFn from 'src/utils/common';
import { classNames } from 'src/utils/className';
import { optionList, getCountryObj } from 'src/utils/countryCodeCalculation';
import { countryCodeSwitchingWarning } from 'src/utils/appConstants';
import { convertToNumber } from '../../utils/personStoreHelper';

function ContactInformation(): ReactElement {
  const primaryRef = useRef(null);
  const secondaryRef = useRef(null);

  const { data: phoneTypes } = useGetPhoneType();

  const [showPopOverForPrimary, SetShowPopOverForPrimary] = useState(false);
  const [showPopOverForSeconadry, SetShowPopOverForSecondary] = useState(false);

  const showPrimaryNumberError = usePersonAlertStore(
    useCallback(state => state.showPrimaryNumberError, []),
  );
  const showSecondaryNumberError = usePersonAlertStore(
    useCallback(state => state.showSecondaryNumberError, []),
  );

  const updatePhnNumberError = usePersonAlertStore(
    useCallback(state => state.updatePhnNumberError, []),
  );

  const person = usePersonAlertStore(
    useCallback(state => state?.alertDetails?.Person?.PersonAlertDetails, []),
  );

  const updateOtherDetails = usePersonAlertStore(
    useCallback(state => state.updateOtherDetails, []),
  );

  const initialAlerts = usePersonAlertStore(
    useCallback(state => state.initialAlert?.Person?.PersonAlertDetails, []),
  );

  const countryList = usePersonAlertStore(
    useCallback(state => state.countryInfo, []),
  );

  const countryCode = useMemo(() => {
    if (countryList) return utilityFn.countryOptions(countryList);
  }, [countryList]);

  const phoneTypeList = utilityFn.formatSelectOptionWithoutSelect(
    phoneTypes,
    'Id',
    'Description',
  );

  //Set mask pattern for primary phn input-mask based on country code
  const maskPatternForPrimaryPhn = useMemo(() => {
    if (person?.PrimaryPhone?.CountryId)
      return utilityFn.patternForMask(
        countryCode,
        person?.PrimaryPhone?.CountryId,
      );
    return '999-999-9999';
  }, [countryCode, person?.PrimaryPhone?.CountryId]);

  //Set mask pattern for secondary phn input-mask based on country code
  const maskPatternForSecondaryPhn = useMemo(() => {
    if (person?.SecondaryPhone?.CountryId)
      return utilityFn.patternForMask(
        countryCode,
        person?.SecondaryPhone?.CountryId,
      );
    return '999-999-9999';
  }, [countryCode, person?.SecondaryPhone?.CountryId]);

  // To show validation error for phn number if it don't meet the length requirement.
  function onCountryCodeChange(
    countryId: number,
    phoneNumber: string | undefined,
    key: string,
    prevCountryId: number,
    comments: string,
  ) {
    const isChangingToUSFormat = utilityFn.isCountryCodeInUSFormat(
      prevCountryId,
      countryId,
      countryCode,
    );

    updateOtherDetails('CountryId', countryId, key);

    if (!utilityFn.isNullOrUndefined(phoneNumber)) {
      let formattedPhone = utilityFn.formattedPhoneNumber(
        phoneNumber,
        countryId,
      );
      if (isChangingToUSFormat) {
        key == 'PrimaryPhone'
          ? SetShowPopOverForPrimary(true)
          : SetShowPopOverForSecondary(true);
        updateOtherDetails(
          'Comments',
          utilityFn.appendToComments(comments, phoneNumber),
          key,
        );
      }
      updateOtherDetails('Number', formattedPhone, key);
      onPhoneNumberFocusOut(phoneNumber, countryId, key);
    }
  }

  function onPhoneNumberFocusOut(phnNumber: any, countryId: any, key: string) {
    const isInvalidPhoneNumber = utilityFn.isPhnIsInvalidOrNot(
      phnNumber,
      countryId,
      countryCode,
    );

    updatePhnNumberError(
      key === 'PrimaryPhone'
        ? 'showPrimaryNumberError'
        : 'showSecondaryNumberError',
      isInvalidPhoneNumber,
    );
  }
  // for primary & secondary phone type
  function renderPhoneDetails(title: string, key: string, reference: any) {
    const phnDetails =
      key === 'PrimaryPhone' ? person?.PrimaryPhone : person?.SecondaryPhone;

    return (
      <div className="space-y-1">
        <h4 className="font-semibold text-gray-500 dark:text-neutral-400">
          {title}
        </h4>
        <div className="flex flex-col gap-3 md:flex-row ">
          <Select
            id={key}
            label="Phone type"
            options={phoneTypeList}
            value={phnDetails?.PhoneTypeId ?? -1}
            onChange={e => {
              updateOtherDetails(
                'PhoneTypeId',
                convertToNumber(e.currentTarget.value),
                key,
              );
              updateOtherDetails('PhoneType', null, key);
            }}
          />
          <div className="grow">
            <label
              htmlFor={key === 'primary' ? 'primary-phone' : 'secondary-phone'}
              className={classNames(inputLabelClasses, 'mb-0.5')}
            >
              Phone number
            </label>
            <div className="relative flex items-stretch ">
              <Popover.Root
                open={
                  key === 'PrimaryPhone'
                    ? showPopOverForPrimary
                    : showPopOverForSeconadry
                }
                onOpenChange={() => {
                  if (key === 'PrimaryPhone' && showPopOverForPrimary)
                    SetShowPopOverForPrimary(false);
                  if (key !== 'PrimaryPhone' && showPopOverForSeconadry)
                    SetShowPopOverForSecondary(false);
                }}
              >
                <Popover.Trigger tabIndex={-1}>
                  <ReactSelectWithFlags
                    id={`${key}`}
                    options={optionList(countryList)}
                    value={getCountryObj(
                      phnDetails?.CountryId ?? 1,
                      countryList,
                    )}
                    setSelectedOptions={(e: any) => {
                      onCountryCodeChange(
                        parseInt(e?.value),
                        phnDetails?.Number,
                        key,
                        phnDetails?.CountryId ?? 1,
                        phnDetails?.Comments ?? '',
                      );
                    }}
                  />
                </Popover.Trigger>
                <InputMask
                  id={key}
                  className={`${inputBaseClasses} ${inputSizeClasses.medium} relative -left-px !rounded-l-none bg-white outline-none`}
                  value={phnDetails?.Number ?? ''}
                  onChange={(e: any) => {
                    updateOtherDetails('Number', e?.target?.value, key);
                  }}
                  pattern={
                    key === 'PrimaryPhone'
                      ? maskPatternForPrimaryPhn
                      : maskPatternForSecondaryPhn
                  }
                  onBlur={(e: any) => {
                    onPhoneNumberFocusOut(
                      e.target.value,
                      phnDetails?.CountryId,
                      key,
                    );
                  }}
                  onDrop={(e: any) => {
                    e.preventDefault();
                    const droppedValue = e.dataTransfer.getData('Text');
                    const isUsFormat = utilityFn.hasUsFormat(
                      countryCode,
                      phnDetails?.CountryId,
                    );
                    const phonenumber = utilityFn.formatPhoneNumber(
                      droppedValue,
                      isUsFormat,
                    );
                    updateOtherDetails('Number', phonenumber, key);
                    onPhoneNumberFocusOut(
                      phonenumber,
                      phnDetails?.CountryId,
                      key,
                    );
                  }}
                />

                <Popover.Portal>
                  <Popover.Content
                    side="top"
                    hideWhenDetached={true}
                    className="relative max-w-60 rounded border border-sky-400 bg-sky-100 p-4 text-sky-950 shadow-xl dark:border-sky-800 dark:bg-sky-950 dark:text-sky-300"
                  >
                    <p className="text-balance text-sm">
                      {countryCodeSwitchingWarning}
                    </p>
                    <Popover.Close
                      className="PopoverClose absolute right-1 top-1"
                      aria-label="Close"
                      onClick={() => {
                        if (key === 'PrimaryPhone')
                          SetShowPopOverForPrimary(false);
                        else SetShowPopOverForSecondary(false);
                      }}
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </Popover.Close>
                    <Popover.Arrow className="PopoverArrow fill-sky-600 dark:fill-sky-800" />
                  </Popover.Content>
                </Popover.Portal>
              </Popover.Root>
            </div>

            {(key === 'PrimaryPhone'
              ? showPrimaryNumberError
              : showSecondaryNumberError) && (
              <p className="mt-1 inline-flex items-center gap-1 text-xs text-red-600 dark:text-red-400 md:text-sm">
                <ExclamationTriangleIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                {utilityFn.validationErrorMsgForPhone(
                  countryCode,
                  phnDetails?.CountryId,
                )}
              </p>
            )}
          </div>
          <Textarea
            ref={reference}
            className="grow-[3]"
            rows={1}
            srOnlyLabel="Comments"
            id="contact-comments"
            value={phnDetails?.Comments}
            onChange={e =>
              updateOtherDetails('Comments', e?.target?.value, key)
            }
            label="Comments (Extension etc.)"
            onDrop={e => {
              e.preventDefault();
              const dropvalue = e.dataTransfer.getData('Text');
              const currentValue = reference.current.value;
              updateOtherDetails('Comments', currentValue + dropvalue, key);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4 md:pt-6">
      <h4 className="pb-1 text-lg font-medium text-black dark:text-white">
        Contact details
      </h4>
      <div className="space-y-6 lg:w-3/4">
        {renderPhoneDetails('Primary contact', 'PrimaryPhone', primaryRef)}
        {renderPhoneDetails(
          'Secondary contact',
          'SecondaryPhone',
          secondaryRef,
        )}
      </div>
    </div>
  );
}
export { ContactInformation };
