import { formatToUtcWithOffset } from 'src/utils/datepickerHelper';

export function formatRequestPayload(
  alertObj: any,
  Id: any,
  usermode: boolean,
  hasCallLimit: boolean,
  countryInfo: any,
) {
  const details = { ...alertObj };
  const formatedDate = formatToUtcWithOffset(details?.alertDate);
  delete details.alertDate;
  const personDetails = {
    ...details,
    Person: formatPersonPayload(
      details?.Person,
      Id,
      usermode,
      hasCallLimit,
      countryInfo,
    ),
    PersonId: details?.Person?.Id,
    StartDate: formatedDate && formatedDate[0],
    EndDate: formatedDate && formatedDate[1],
  };
  if (usermode) {
    return {
      ...personDetails,
      EnteredBy: '',
      EnteredDate: new Date().toISOString(),
      Id: 0,
    };
  }
  return { ...personDetails };
}

function formatPersonPayload(
  personDetails: any,
  Id: number,
  usermode: boolean,
  hasCallLimit?: boolean,
  countryInfo?: any,
) {
  const details = { ...personDetails };
  const personObj = {
    ...details,
    Birthdate:
      details?.PersonAlertDetails?.Birthdate !== null &&
      details?.PersonAlertDetails?.Birthdate !== undefined &&
      details?.PersonAlertDetails?.Birthdate !== ''
        ? new Date(details?.PersonAlertDetails?.Birthdate).toISOString()
        : null,
    Age:
      details?.PersonAlertDetails?.Age === '' ||
      details?.PersonAlertDetails?.Age == undefined
        ? null
        : details?.PersonAlertDetails?.Age,
    AccountId: Id,
    PersonAlertDetails: formatAlertDetails(
      details?.PersonAlertDetails,
      countryInfo,
    ),
    PersonCallLimit:
      hasCallLimit && hasCallLimit === true ? details?.PersonCallLimit : null,
  };

  if (usermode) {
    return {
      ...personObj,
      Prefix: '',
      Suffix: '',
      EntityId: create_UUID(),
    };
  }
  return {
    ...personObj,
  };
}

function formatAlertDetails(personObj: any, countryInfo: any) {
  const usCountryId = countryInfo?.find(
    (x: any) => x.Abbreviation === 'US',
  )?.Id;

  if (!personObj) {
    return {
      PrimaryPhone: { CountryId: usCountryId },
      SecondaryPhone: { CountryId: usCountryId },
      PrimaryAddress: {},
      Birthdate: null,
    };
  }

  return {
    ...personObj,
    PrimaryPhone: ensureCountryId(personObj.PrimaryPhone || {}, usCountryId),
    SecondaryPhone: ensureCountryId(
      personObj.SecondaryPhone || {},
      usCountryId,
    ),
    PrimaryAddress: {
      ...personObj.PrimaryAddress,
      CountryId:
        personObj.PrimaryAddress?.CountryId === -1
          ? null
          : personObj.PrimaryAddress?.CountryId,
      StateProvinceId:
        personObj.PrimaryAddress?.StateProvinceId === -1
          ? null
          : personObj.PrimaryAddress?.StateProvinceId,
    },
    Birthdate: personObj.Birthdate !== '' ? personObj.Birthdate : null,
    Age: personObj.Age !== '' ? personObj.Age : null,
  };
}

// to ensure CountryId is set if it's missing
const ensureCountryId = (phoneObj: any, usCountryId: any) => {
  return {
    ...phoneObj,
    CountryId: phoneObj?.CountryId ?? usCountryId,
  };
};

function create_UUID() {
  let dt = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}
