import React, { ReactElement, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { useGetStoredPermissions } from 'src/hooks';

import { createMarkup } from 'src/utils/common';
import { activeStyles } from '../utils/personAlertConstants';
import { classNames } from 'src/utils/className';

import { usePersonAlertStore } from 'src/features/AddEditPersonAlert/store/personAlertStore';

import { PersonAlertListType } from '../types/personAlerts';
import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

type ALertListType = {
  alertItem: PersonAlertListType;
  handleRowClick: (val: PersonAlertListType) => void;
  selectedAlert: PersonAlertListType | undefined;
  setSelectedAlert: any;
  previewRefetch: any;
  setDocumentPreviewModal: any;
};

function AlertListItem({
  alertItem,
  handleRowClick,
  selectedAlert,
  setSelectedAlert,
  previewRefetch,
  setDocumentPreviewModal,
}: ALertListType): ReactElement {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const permissions = useGetStoredPermissions();

  const setPersonApiCalled = usePersonAlertStore(
    useCallback(state => state?.setPersonApiCalled, []),
  );

  function previewHandler() {
    setSelectedAlert(alertItem);
    if (
      !permissions?.CanSetUpPersonAlerts &&
      permissions?.CanViewPersonAlerts
    ) {
      setTimeout(() => {
        previewRefetch().then(() => {
          setDocumentPreviewModal(true);
        });
      }, 500);
    }
  }

  return (
    <>
      <div
        tabIndex={0}
        className={`relative flex flex-wrap gap-5 rounded bg-white p-2 shadow focus-visible:outline  focus-visible:outline-[3px] focus-visible:outline-offset-[3px] focus-visible:outline-blue-600 dark:bg-neutral-800 dark:focus-visible:outline-blue-400 lg:gap-x-5${
          selectedAlert?.Id === alertItem?.Id ? activeStyles : ''
        }`}
        onClick={() => {
          handleRowClick(alertItem);
        }}
        onDoubleClick={() => {
          useSaveChangesParamStore.getState().setIsDirty(false);
          setPersonApiCalled(false);
          if (
            !permissions?.CanSetUpPersonAlerts &&
            permissions?.CanViewPersonAlerts
          ) {
            queryClient.invalidateQueries(['get_person_alert_preview'], {
              refetchActive: false,
            });

            previewHandler();
            return;
          }
          queryClient.invalidateQueries(['add-edit-person-alert'], {
            refetchActive: false,
          });
          navigate(
            `/PersonAlert/Edit/${alertItem?.Id}?accountId=${alertItem?.Person?.AccountId}`,
          );
        }}
      >
        <div className="flex min-w-[70px] basis-[45%] flex-col gap-3 p-2 md:basis-[30%] lg:basis-[7%]">
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              CID
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.Person?.Account?.CID}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              PID
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.Person?.Account?.PID}
            </p>
          </div>
        </div>
        <div className="min-w-[92px] basis-[45%] gap-2 p-2 md:basis-[30%] lg:basis-[10%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Alert Number
          </label>
          <p className="mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white">
            {alertItem?.Id}
          </p>
        </div>
        <div className="flex min-w-[70px] basis-[45%] flex-col gap-3 p-2 md:basis-[30%] lg:basis-[18%]">
          <div className="flex flex-col items-baseline gap-1 xl:flex-row">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              First Name
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.Person?.FirstName}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Last Name
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.Person?.LastName}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Alternate Name
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.Person?.PreferredName}
            </p>
          </div>
        </div>

        <div className="min-w-[92px] basis-[45%] gap-2 p-2 md:basis-[30%] lg:basis-[8%]">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Birth date
          </label>
          <p
            className={classNames(
              'mt-0.5 text-xs font-bold tracking-wide text-black dark:text-white',
              alertItem?.Person?.Birthdate === null ? 'pt-6' : '',
            )}
          >
            {alertItem?.Person?.Birthdate != null
              ? dayjs(alertItem?.Person?.Birthdate).format('MM/DD/YYYY')
              : ''}
          </p>
        </div>
        <div className="flex min-w-[70px] basis-[45%] flex-col gap-3 p-2 md:basis-[30%] lg:basis-[20%]">
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Start Date
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.StartDate != null
                ? dayjs(alertItem?.StartDate).format('MM/DD/YYYY H:mm:ss')
                : ''}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Expiration Date
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.EndDate != null
                ? dayjs(alertItem?.EndDate).format('MM/DD/YYYY H:mm:ss')
                : ''}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Entered Date
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.EnteredDate != null
                ? dayjs(alertItem?.EnteredDate).format('MM/DD/YYYY H:mm:ss')
                : ''}
            </p>
          </div>
        </div>
        <div className="flex min-w-[70px] basis-[45%] flex-col gap-3 p-2 md:basis-[30%] lg:basis-[20%]">
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Updated by
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.UpdatedBy ?? ''}
            </p>
          </div>
          <div className="flex flex-col items-baseline gap-1 xl:flex-row xl:flex-wrap">
            <label className="block min-w-24 whitespace-nowrap text-[10px] font-medium uppercase leading-none tracking-wide opacity-70">
              Updated date
            </label>
            <p className="prevent-text-breakout text-xs font-bold leading-tight tracking-wide text-black dark:text-white">
              {alertItem?.UpdatedOn != null
                ? dayjs(alertItem?.UpdatedOn).format('MM/DD/YYYY H:mm:ss')
                : ''}
            </p>
          </div>
        </div>
        <div className="basis-full p-2">
          <label className="block whitespace-nowrap text-[10px] font-medium uppercase tracking-wide opacity-70">
            Situation
          </label>
          <div
            className="prevent-text-breakout prose max-w-full dark:prose-invert "
            dangerouslySetInnerHTML={createMarkup(
              alertItem?.SituationDescription || '',
            )}
          ></div>
        </div>
      </div>
    </>
  );
}

export { AlertListItem };
