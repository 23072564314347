import React, {
  ReactElement,
  Fragment,
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { PrinterIcon } from '@heroicons/react/20/solid';

import {
  Spinner,
  Button,
  RequiredWarningMessage,
  CustomCancelModal,
} from 'src/ui/components';
import {
  PersonInformation,
  AccountSelection,
  AlertInformations,
} from './components';

import { useTitle, useAuth } from 'src/hooks';
import { useSavePersonAlert } from './api';
import { useMutableObserver } from 'src/hooks/useMutableObserver';

import { toCapitalise } from 'src/utils/common';
import { usePersonAlertStore } from './store/personAlertStore';
import { showErrorToast, showSuccessToast } from 'src/utils/ToastNotification';
import { formatRequestPayload } from './utils/payloadRequestFormatter';
import { cancelWarningMsg } from 'src/utils/appConstants';
import {
  personAlertTabs,
  tabSections,
} from './utils/addEditPersonAlertConstant';

import { useSaveChangesParamStore } from 'src/store/useSaveChangesParamStore';

export default function AddEditPersonAlert(): ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient: any = useQueryClient();

  const { id } = useParams();

  const dateRangeWrapper = useRef<any>();

  const { token, tokenType } = useAuth();

  const accountId = new URLSearchParams(useLocation().search).get('accountId'); // Get the accountId from the query string

  const warningMsgRef = useRef<HTMLDivElement | null>(null);

  const { isMessageChanged } = useMutableObserver(warningMsgRef);

  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  const { mutate: onSavePersonAlert, isLoading: isSaving } =
    useSavePersonAlert();

  const userMode = usePersonAlertStore(
    useCallback(state => state.userMode, []),
  );

  const updateUserMode = usePersonAlertStore(
    useCallback(state => state.updateUserMode, []),
  );

  const alertDetails = usePersonAlertStore(
    useCallback(state => state.alertDetails, []),
  );

  const getPersonAlerts = usePersonAlertStore(
    React.useCallback(state => state.getPersonAlerts, []),
  );

  const isEditApiLoading = usePersonAlertStore(
    useCallback(state => state.isEditApiLoading, []),
  );

  const accountsInfo = usePersonAlertStore(
    useCallback(state => state.accountsInfo, []),
  );

  const isSaveClicked = usePersonAlertStore(
    useCallback(state => state.isSaveClicked, []),
  );

  const setSaveClicked = usePersonAlertStore(
    useCallback(state => state.setSaveClicked, []),
  );

  const enableAlertTabs = usePersonAlertStore(
    useCallback(state => state.enableAlertTabs, []),
  );

  const hasCallLimit = usePersonAlertStore(
    useCallback(state => state.hasCallLimit, []),
  );

  const showPrimaryNumberError = usePersonAlertStore(
    useCallback(state => state.showPrimaryNumberError, []),
  );

  const showSecondaryNumberError = usePersonAlertStore(
    useCallback(state => state.showSecondaryNumberError, []),
  );

  const disableSave = usePersonAlertStore(
    useCallback(state => state?.checkDataHasChanged, []),
  );

  const setIsDirty = useSaveChangesParamStore(
    useCallback(state => state.setIsDirty, []),
  );

  const countryInfo = usePersonAlertStore(
    useCallback(state => state?.countryInfo, []),
  );

  const isDirty = useSaveChangesParamStore(
    useCallback(state => state.isDirty, []),
  );

  const deleteAlertDetails = usePersonAlertStore(
    useCallback(state => state?.deleteAlertDetails, []),
  );

  const setPersonApiCalled = usePersonAlertStore(
    useCallback(state => state?.setPersonApiCalled, []),
  );

  const isSpinnerLoading = isEditApiLoading || isSaving;

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        const confirmLeave = window.confirm(cancelWarningMsg);
        if (confirmLeave) {
          return e;
        } else {
          e.preventDefault();
        }
      }
    };

    if (isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (location?.pathname.split('/').includes('Edit'))
      updateUserMode && updateUserMode('Edit');
  }, [location?.pathname, updateUserMode]);

  //fetch api for person alerts
  useQuery(
    ['add-edit-person-alert', id, accountId],
    () => {
      getPersonAlerts({
        id,
        accountId,
        token,
        tokenType,
      });
    },
    { staleTime: Infinity },
  );

  /*user entity id when edit and copy */
  const getUserMode = useMemo(() => {
    if (userMode === '') return 'Create person alert';
    if (userMode === 'Edit') return 'Edit person alert';
    return '';
  }, [userMode]);

  useTitle(toCapitalise(getUserMode));

  function onPrintPreviewHandler() {
    window.open(
      `/PersonAlert/PrintPreview/${id}?accountId=${accountId}`,
      '_blank',
    );
  }

  function handleCancel() {
    navigate('/PersonAlert');
    queryClient.invalidateQueries(['add-edit-person-alert'], {
      refetchActive: false,
    });
    setSaveClicked(false);
    setIsDirty(false);
    setPersonApiCalled(false);
  }

  function onCancel() {
    if (disableSave() === false) {
      setShowCancelModal(true);
    } else {
      handleCancel();
    }
  }

  function onSavePersonAlertHandler() {
    if (
      !enableAlertTabs('accounts-tab') ||
      !enableAlertTabs('personInformation-tab') ||
      !enableAlertTabs('alert-tab') ||
      showPrimaryNumberError ||
      showSecondaryNumberError
    )
      return;
    setPersonApiCalled(false);
    const details = { ...alertDetails };
    const formattedPayload = formatRequestPayload(
      details,
      accountsInfo?.Id,
      userMode !== 'Edit',
      hasCallLimit,
      countryInfo,
    );

    setSaveClicked(false);
    if (userMode !== 'Edit') {
      onSavePersonAlert(
        { params: formattedPayload },
        {
          onSuccess: () => {
            setIsDirty(false);
            setPersonApiCalled(false);
            deleteAlertDetails();
            showSuccessToast({
              message: 'Person alert was successfully created.',
            });
            queryClient.invalidateQueries(['add-edit-person-alert'], {
              refetchActive: false,
            });
            navigate('/PersonAlert');
          },
          onError: err => {
            showErrorToast({ message: err?.Message });
          },
        },
      );
    } else {
      setIsDirty(false);
      onSavePersonAlert(
        {
          params: formattedPayload,
          action: 'Edit',
        },
        {
          onSuccess: () => {
            showSuccessToast({
              message: 'Person alert was successfully updated.',
            });
            queryClient.invalidateQueries(['add-edit-person-alert'], {
              refetchActive: false,
            });
            navigate('/PersonAlert');
          },
          onError: err => {
            showErrorToast({ message: err?.Message });
          },
        },
      );
    }
  }

  function renderPageHeader(): ReactElement {
    return (
      <div className="flex flex-wrap items-center justify-between gap-2 border-t border-gray-200 bg-white p-2 shadow dark:border-neutral-700/50 dark:bg-neutral-800 sm:gap-4 sm:px-3 lg:px-4">
        <div className="flex flex-1 shrink-0 items-end gap-4 sm:gap-8">
          <h2 className="truncate text-lg font-semibold text-gray-950 dark:text-white sm:text-xl sm:tracking-tight">
            {userMode === 'Edit' ? 'Edit person alert' : 'Create person alert'}
          </h2>
        </div>

        {userMode === 'Edit' && (
          <Button
            variant="primary"
            size="small"
            onClick={() => onPrintPreviewHandler()}
          >
            <PrinterIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            <span className="hidden sm:inline-block">Print</span>
          </Button>
        )}
      </div>
    );
  }

  return (
    <main className="flex flex-1 flex-col">
      {renderPageHeader()}
      {isSpinnerLoading && (
        <div className="bg-gray-900/8 absolute inset-0 z-20 grid place-content-center backdrop-blur-sm">
          <Spinner />
        </div>
      )}
      <div className="h-[1px] grow">
        <div
          className="relative h-full max-h-full overflow-y-scroll scroll-smooth"
          ref={dateRangeWrapper}
        >
          <div className="container mx-auto space-y-2 p-3 sm:space-y-4 lg:p-4">
            {!isEditApiLoading && (
              <Fragment>
                <AccountSelection />
                <PersonInformation refValue={dateRangeWrapper} />
                <AlertInformations />
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <div className="border-t border-light-light  bg-white dark:border-dark-medium dark:bg-neutral-800">
        <div
          ref={warningMsgRef}
          className="container mx-auto flex flex-col gap-4 p-2 sm:flex-row sm:justify-end md:flex-row"
        >
          {isSaveClicked &&
            (!enableAlertTabs('accounts-tab') ||
              !enableAlertTabs('personInformation-tab') ||
              !enableAlertTabs('alert-tab')) && (
              <div
                className={`mr-3 flex items-center gap-2 ${
                  isMessageChanged ? 'animate-shake' : ''
                }`}
              >
                <span className="relative flex h-3 w-3 shrink-0 items-center justify-center">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75" />
                  <span className="relative inline-flex h-2 w-2 rounded-full bg-red-500" />
                </span>
                <span className="text-sm font-medium text-red-700 dark:text-red-400">
                  <RequiredWarningMessage
                    tabs={personAlertTabs}
                    tabContents={tabSections}
                    enableTab={enableAlertTabs}
                  />
                </span>
              </div>
            )}
          <div className="flex gap-[1ch]">
            <Button
              className="grow md:grow-0"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="grow md:grow-0"
              variant="primary"
              onClick={() => {
                setSaveClicked(true);
                onSavePersonAlertHandler();
              }}
              disabled={isSpinnerLoading || disableSave() ? true : false}
            >
              Save
            </Button>
          </div>
        </div>
        {showCancelModal === true && !disableSave() && (
          <CustomCancelModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            handleCancel={handleCancel}
          />
        )}
      </div>
    </main>
  );
}
